import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { Map, fromJS } from 'immutable';
import { zeroIfUndefined, addFloats } from 'utils/HelperFunctions';

export function uiDataClosePO(state = initialAppState.getIn(['uiData', 'close']), action) {
    switch (action.type) {

        case constants.FETCH_CLOSE_PO_DATA_REQUESTED:
            return state.set('isFetchingData', true);
        case constants.FETCH_CLOSE_PO_DATA_REJECTED:
            return state.set('isFetchingData', false);
        case constants.CLOSE_PO_REQUESTED:
            return state.set('errorMessage', []).set('taxErrorMessage', []).set('isClosingPO',true);
        case constants.CLOSE_PO_RESOLVED:
			return state.set('isClosingPO',false);
		case constants.CLOSE_PO_CANCELLED:
            return state.set('isClosingPO',false);
        case constants.CLOSE_PO_REJECTED:
            return state.set('errorMessage', action.errorMessage).set('isClosingPO',false);
        case constants.TAX_ERROR_MESSAGE:
            return state.set('taxErrorMessage', [action.taxErrorMessage]);
        case constants.FETCH_CLOSE_PO_DATA_RESOLVED: {
            return state.setIn(['invoiceDetails', 'subTotal'], action.subTotal)
				.set('isFetchingData', false);
		}
		case constants.DOT_INSPECTION__DISPLAY: 
			return state.set('isClosingPO', false);
		case constants.DOT_INSPECTION__SAVE_CONFIRMATION_RESOLVED: 
			return state.set('isClosingPO', true);
		case constants.DOT_INSPECTION__SAVE_FORM_RESOLVED:
			return state.set('isClosingPO', true);
		case constants.BIT_INSPECTION__DISPLAY: 
			return state.set('isClosingPO', false);
		case constants.BIT_INSPECTION__SAVE_CONFIRMATION_RESOLVED: 
			return state.set('isClosingPO', true);
		case constants.BIT_INSPECTION__SAVE_FORM_RESOLVED:
			return state.set('isClosingPO', true);
		case constants.FRA_INSPECTION__DISPLAY: 
			return state.set('isClosingPO', false);
		case constants.FRA_INSPECTION__SAVE_CONFIRMATION_RESOLVED: 
			return state.set('isClosingPO', true);
		case constants.FRA_INSPECTION__SAVE_FORM_RESOLVED:
			return state.set('isClosingPO', true);
        case constants.CRANE_INSPECTION__DISPLAY: 
			return state.set('isClosingPO', false);
		case constants.CRANE_INSPECTION__SAVE_CONFIRMATION_RESOLVED: 
			return state.set('isClosingPO', true);
		case constants.CRANE_INSPECTION__SAVE_FORM_RESOLVED:
			return state.set('isClosingPO', true);	        

        case constants.SAVE_INVOICE_DETAILS:

            let value = action.poInvoiceDetails.get('value');
            switch (action.poInvoiceDetails.get('key')) {
                case "makeSubmittedByReadOnly":
                    return state.setIn(['invoiceDetails', 'makeSubmittedByReadOnly'], value);
                case constants.INVOICE_FIELD_TYPE.INVOICE_NO:
                    return state.setIn(['invoiceDetails', 'invoiceNo'], value);

                case constants.INVOICE_FIELD_TYPE.INVOICE_DATE:
                    return state.setIn(['invoiceDetails', 'invoiceDate'], value);

                case constants.INVOICE_FIELD_TYPE.NAME:
                    return state.setIn(['invoiceDetails', 'submittedBy'], value);

                case constants.INVOICE_FIELD_TYPE.REPAIR_COMPLETION_DATE:
                    return state.setIn(['invoiceDetails', 'repairCompletionDate'], value);
                case constants.INVOICE_FIELD_TYPE.REPAIR_START_DATE:
                    return state.setIn(['invoiceDetails', 'repairStartDate'], value);
                case constants.INVOICE_FIELD_TYPE.ODOMETER:
                    // If value does not contain all numbers, set to zero.
                    if (/^-?\d+$/.test(value) === false) {
                        value = 0;                        
                    }
                    return state.setIn(['invoiceDetails', 'odometer'],value != null ? value.toString().replace(".",'') : 0);
                case constants.INVOICE_FIELD_TYPE.ENGINE_HOURS:                    
                    // If value does not contain all numbers, set to zero.
                    if (/^-?\d+$/.test(value) === false) {
                        value = 0;                        
                    }
                    return state.setIn(['invoiceDetails', 'engineHours'],value != null ?  value.toString().replace(".",'') : 0);
                case constants.INVOICE_FIELD_TYPE.IS_TAX_EXEMPTED:
                        return state.setIn(['invoiceDetails', 'isTaxExempted'], value);
                case constants.INVOICE_FIELD_TYPE.TAX_AMOUNT:
                    //Check if tax amount is for Canada, then consider added tax amount;
                    let taxAmount = value != '' ? value : 0.00;
                    let taxType = "";
                     //If the line item returned is a tax atacode then add this information to state
                    if ( action.poInvoiceDetails.get('taxType').includes("TAX")||
                        action.poInvoiceDetails.get('taxType').includes("HST") ||
                        action.poInvoiceDetails.get('taxType').includes("GST") ||
                        action.poInvoiceDetails.get('taxType').includes("QST") ||
                        action.poInvoiceDetails.get('taxType').includes("PST") ||
                        action.poInvoiceDetails.get('taxType').includes("tax") ) {
                        let HST = zeroIfUndefined(state.getIn(['invoiceDetails', 'taxes', 'HST']));
                        let GST = zeroIfUndefined(state.getIn(['invoiceDetails', 'taxes', 'GST']));
                        let QST = zeroIfUndefined(state.getIn(['invoiceDetails', 'taxes', 'QST']));
                        let PST = zeroIfUndefined(state.getIn(['invoiceDetails', 'taxes', 'PST']));

                        //If already tax amount exists, so user is trying to update the new tax amount
                        if (action.poInvoiceDetails.get('taxType').includes("HST")) {
                            HST = taxAmount;
                            taxType = "HST";
                        } else if (action.poInvoiceDetails.get('taxType').includes("GST")) {
                            GST = taxAmount;
                            taxType = "GST";
                        } else if (action.poInvoiceDetails.get('taxType').includes("QST")) {
                            QST = taxAmount;
                            taxType = "QST";
                        } else if (action.poInvoiceDetails.get('taxType').includes("PST")) {
                            PST = taxAmount;
                            taxType = "PST";
                        } else {
                            taxType = "tax";
                        }
                        if (HST > 0 || GST || QST || PST) {
                            taxAmount = addFloats(HST, GST, QST, PST);
                            state.setIn(['invoiceDetails', 'taxAmount'], taxAmount);
                        }
                    }
                    //if the lineItem is tax add value if not return state
                    if (taxType !== "") {

                        return state.setIn(['invoiceDetails', 'taxAmount'],taxAmount.toString() != '.' ? taxAmount.toString() : 0)
                            .setIn(['invoiceDetails', 'taxes', taxType], value.toString() != ''  || value.toString() != '.' ? value.toString() : 0.00);
                    } else {
                        return state;
                    }
                case constants.INVOICE_FIELD_TYPE.EXEMPTED_REASON:
                    return state.setIn(['invoiceDetails', 'exemptedReason'], value);

                case constants.INVOICE_FIELD_TYPE.IS_CREDIT_PAYMENT:
                    return state.setIn(['invoiceDetails', 'isPaymentCreditToAccount'], value);

                case constants.INVOICE_FIELD_TYPE.IS_WORK_COMPLETED:
                    return state.setIn(['invoiceDetails', 'isWorkCompleted'], value);
                case constants.INVOICE_FIELD_TYPE.IS_INVOICE_REPRODUCIBLE:
                    return state.setIn(['invoiceDetails','isInvoiceReproducible'],value);
                case constants.INVOICE_FIELD_TYPE.REF_NUMBER:
                    return state.setIn(['invoiceDetails', 'refNumber'], value);
                case constants.INVOICE_FIELD_TYPE.CLIENT_REF_NO:
                    return state.setIn(['invoiceDetails', 'clientReferenceNo'], value);


                default:
                    return state;
            }

        case constants.RESET_INVOICE_DETAILS:
            /* Resets the invoice details state*/
            let invoiceDetails = Map({
                invoiceNo: undefined,
                invoiceDate: new Date(),
                submittedBy: undefined,
                repairCompletionDate: undefined,
                isTaxExempted: false,
                taxAmount: 0.00,
                exemptedReason: "TaxExempt",
                canShowMultipleTaxTypes: false,
                isPaymentCreditToAccount: false,
                isWorkCompleted: false,
                isInvoiceReproducible:false,
                subTotal: 0.00,
                taxes: Map(),
                repairStartDate: new Date(),
                clientReferenceNo:undefined

            })
                ;

            return state.setIn(['invoiceDetails'], invoiceDetails).deleteIn(['popOver']).set('errorMessage', []).set('taxErrorMessage', []).set('isClosingPO',false);
        case constants.POP_OVER_DISPLAY_SHOW_INVOICE:
            return state.setIn(['popOver', 'isPopOverDisplayedShowInvoiceNo'], action.popOverDisplayShowInvoiceNo, !state.getIn(['popOver', 'isPopOverDisplayedShowInvoiceNo'], action.popOverDisplayShowInvoiceNo));
        case constants.POP_OVER_DISPLAY_SHOW_INVOICE_DATE:
            return state.setIn(['popOver', 'isPopOverDisplayedShowInvoiceDate'], action.popOverDisplayShowInvoiceDate, !state.getIn(['popOver', 'isPopOverDisplayedShowInvoiceDate'], action.popOverDisplayShowInvoiceDate));
        case constants.POP_OVER_DISPLAY_SHOW_INVOICE_NAME:
            return state.setIn(['popOver', 'isPopOverDisplayedShowInvoiceName'], action.popOverDisplayShowInvoiceName, !state.getIn(['popOver', 'isPopOverDisplayedShowInvoiceName'], action.popOverDisplayShowInvoiceName));
        case constants.POP_OVER_DISPLAY_SHOW_REPAIR_COMPLETE_DATE:
            return state.setIn(['popOver', 'isPopOverDisplayedShowRepairCompleteDate'], action.popOverDisplayShowRepairCompleteDate, !state.getIn(['popOver', 'isPopOverDisplayedShowRepairCompleteDate'], action.popOverDisplayShowRepairCompleteDate));
        case constants.POP_OVER_DISPLAY_SHOW_REPAIR_START_DATE:
            return state.setIn(['popOver', 'isPopOverDisplayedShowRepairStartDate'], action.popOverDisplayShowRepairStartDate, !state.getIn(['popOver', 'isPopOverDisplayedShowRepairStartDate'], action.popOverDisplayShowRepairStartDate));
        case constants.POP_OVER_DISPLAY_SHOW_ODOMETER:
            return state.setIn(['popOver', 'isPopOverDisplayedShowOdometer'], action.popOverDisplayShowOdometer, !state.getIn(['popOver', 'isPopOverDisplayedShowOdometer'], action.popOverDisplayShowOdometer));            
        case constants.POP_OVER_DISPLAY_SHOW_ENGINE_HOURS:
            return state.setIn(['popOver', 'isPopOverDisplayedShowEngineHours'], action.popOverDisplayShowEngineHours, !state.getIn(['popOver', 'isPopOverDisplayedShowEngineHours'], action.popOverDisplayShowEngineHours));            
        case constants.POP_OVER_DISPLAY_SHOW_PAYMENT_CREDIT:
            return state.setIn(['popOver', 'isPopOverDisplayedShowPaymentCredit'], action.popOverDisplayShowPaymentCredit, !state.getIn(['popOver', 'isPopOverDisplayedShowPaymentCredit'], action.popOverDisplayShowPaymentCredit));
        case constants.POP_OVER_DISPLAY_SHOW_WORK_COMPLETE:
            return state.setIn(['popOver', 'isPopOverDisplayedShowWorkComplete'], action.popOverDisplayShowWorkComplete, !state.getIn(['popOver', 'isPopOverDisplayedShowWorkComplete'], action.popOverDisplayShowWorkComplete));
        case constants.POP_OVER_DISPLAY_SHOW_STANDARD: 
            return state.setIn(['popOver', 'isPopOverDisplayedShowStandard'], action.popOverShowStandard, !state.getIn(['popOver', 'isPopOverDisplayedShowStandard'], action.popOverShowStandard));
        case constants.POP_OVER_DISPLAY_SHOW_TAX:
            return state.setIn(['popOver', 'isPopOverDisplayedShowTax'], action.popOverDisplayShowTax, !state.getIn(['popOver', 'isPopOverDisplayedShowTax'], action.popOverDisplayShowTax));
        case "POP_OVER_DISPLAY_SHOW_TAX_EXE":
            return state.setIn(['popOver', 'isPopOverDisplayShowTaxExe'], action.popOverDisplayShowTaxExe, !state.getIn(['popOver', 'isPopOverDisplayShowTaxExe'], action.popOverDisplayShowTaxExe));
        case "POP_OVER_DISPLAY_SHOW_TAX_EXE_NOT":
            return state.setIn(['popOver', 'isPopOverDisplayShowTaxExeN'], action.popOverDisplayShowTaxExeN, !state.getIn(['popOver', 'isPopOverDisplayShowTaxExeN'], action.popOverDisplayShowTaxExeN));
        case constants.POP_OVER_DISPLAY_SHOW_TAX_CANADA:
            return state.setIn(['popOver', 'isPopOverDisplayedShowTaxCanada'], action.popOverDisplayShowTaxCanada, !state.getIn(['popOver', 'isPopOverDisplayedShowTaxCanada'], action.popOverDisplayShowTaxCanada));
        case "POP_OVER_DISPLAY_SHOW_REF_NUMBER":
			return state.setIn(['popOver', 'isPopOverDisplayShowRefNumber'], action.popOverDisplayShowRefNumber, !state.getIn(['popOver', 'isPopOverDisplayShowRefNumber'], action.popOverDisplayShowRefNumber));
		case constants.CLOSE_PO__HIDE_ALL_POPOVERS:
			return state.set('popOver', state.get('popOver').map((show)=>(false)));
        case constants.SHOW_CLOSE_PO_ODOMETER_WARNING:
            return state.setIn(['showOdometerWarning'], action.showWarning);            
        case constants.SHOW_CLOSE_PO_ENGINE_HOURS_WARNING:
            return state.setIn(['showEngineHoursWarning'], action.showWarning);                        
        default:
            return state;
    }
}

export function serverDataClosePO(state = initialAppState.getIn(['serverData', 'close']), action) {
    switch (action.type) {
        case constants.FETCH_CLOSE_PO_DATA_RESOLVED:
            return Map({
                vendor: fromJS(action.purchaseOrderData.vendor),
				vehicleDetails: fromJS(action.purchaseOrderData.vehicleDetails),
                purchaseOrder: fromJS(action.purchaseOrderData.purchaseOrder),
                complaints: fromJS(action.purchaseOrderData.complaints),
                notes: fromJS(action.purchaseOrderData.notes),
                lineItems: fromJS(action.purchaseOrderData.lineItems),
                vehicleDetailsToShowOdometerWarnings:fromJS(action.purchaseOrderData.vehicleDetailsToShowOdometerWarnings),
                applicableTaxTypes:fromJS(action.purchaseOrderData.applicableTax),
                vendorReferenceNo:fromJS(action.purchaseOrderData.vendorReferenceNo)
			});

             case constants.CLOSE_PO_FETCH_LINE_ITEMS:             
             return state.setIn(['lineItems'], fromJS(action.lineItems));
        case constants.RESET_INVOICE_DETAILS:
            return Map({
                vendor: Map(),
                vehicleDetails: Map(),
                purchaseOrder:Map(),
                complaints: Map(),
                notes:Map(),
                lineItems: Map(),
                vehicleDetailsToShowOdometerWarnings:Map()
            });
        default:
            return state;
    }
}