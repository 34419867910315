import { jwtDecode } from 'jwt-decode';

const initialState = {
  isPaymentStatusCheckEnabled: false,
  poPermissions: [],
  courteseyDeliveryPermissions: [],
  accountSettingPermissions: [],
  bankAccountPermissions: [],
  taxIdPermssions: [],
  authorizationEmailPermissions: [],
  documentPermisions: [],
  servicesPermissions: [],
  isProductCodeFeatureEnabled: false,
  isFlatRateFeatureEnabled: false,
  isPreventFractionalQtyFeatureEnabled: false,
  isLockNationalAccountPricingFeatureEnabled: false,
  isHideClientPricingFeatureEnabled: false,
  isPreventLineEditFeatureEnabled: false,
  isPMServicesReadOnlyFeatureEnabled: false,
  isMissingProductCodeWorkFlowEnabled: false,
  isStoreAssociationLineIdFeatureEnabled: false,
  isDeleteAssociatedLinesFeatureEnabled: false,
  isQtySyncFeatureEnabled: false,
  isAllowMutuallyExclusiveATAsFeatureEnabled: false,
  isAllowMultipleLinesWithSameATAFeatureEnabled: false,
  isReviewOpenPOsUponLoginEnabled: false,
  isPriceControlFeatureEnabled: false,
  isIEWarningFeatureEnabled: false,
  isAccidentAssignmentFeatureEnabled: undefined,
  overrideTaxThresholdLimit: false
}

const appStateReducer = (state, action) => {
  switch (action.type) {
    case "PERSONA_USER_CHANGED": {
      return { ...state, personaUser: action.personaUser }
    }
    case "PERSONA_TOKEN_CHANGED": {

      if (action.token !== undefined) {
        // Store Persona token and user id 
        const jwtToken = jwtDecode(action.token);
        return { ...state, personaToken: action.token, personaUserId: jwtToken.id, isPersonaUserLoggedIn: true }
      }
      else
        return { ...state, personaToken: undefined, personaUserId: undefined, isPersonaUserLoggedIn: false }
    }

    case "TOKEN_CHANGED": {
      if (action.token !== undefined) {

        // Parse token
        const jwtToken = jwtDecode(action.token);
        const audien = jwtToken.aud;

        // If token is for a vendor, set all permissions
        if (["VMS",
          "FCASECUREAUTH",
          "LESSCHWABSECUREAUTH",
          "VMS_MEXICO",
          "ARISINGLESIGNON",
          "NavistarSSO"].some(app => app === audien)
        ) {
          return {
            ...state,
            token: action.token,
            poPermissions: ["VW", "CR", "VD", "ED", "CL"],
            courteseyDeliveryPermissions: ["VW", "CL"],
            accountSettingPermissions: ["VW", "ED"],
            bankAccountPermissions: ["VW", "ED"],
            taxIdPermssions: ["VW"],
            authorizationEmailPermissions: ["VW", "ED"],
            documentPermisions: ["VW", "ED"],
            servicesPermissions: ["VW", "ED"],
          }
        }
        else if (audien == "ARIEXTERNALSIGNON") {
          // If token is for a user-vendor, set only permissions that are available in the token
          if (jwtToken.vendor !== undefined)
            return {
              ...state,
              token: action.token,
              poPermissions: jwtToken.vendor.permissions.PO !== undefined ? jwtToken.vendor.permissions.PO : [],
              courteseyDeliveryPermissions: jwtToken.vendor.permissions.CD !== undefined ? jwtToken.vendor.permissions.CD : [],
              accountSettingPermissions: jwtToken.vendor.permissions.AS !== undefined ? jwtToken.vendor.permissions.AS : [],
              bankAccountPermissions: jwtToken.vendor.permissions.BA !== undefined ? jwtToken.vendor.permissions.BA : [],
              taxIdPermssions: jwtToken.vendor.permissions.TX !== undefined ? jwtToken.vendor.permissions.TX : [],
              authorizationEmailPermissions: jwtToken.vendor.permissions.AUTH_EMAIL !== undefined ? jwtToken.vendor.permissions.AUTH_EMAIL : [],
              documentPermisions: jwtToken.vendor.permissions.DOC !== undefined ? jwtToken.vendor.permissions.DOC : [],
              servicesPermissions: jwtToken.vendor.permissions.SERVICES !== undefined ? jwtToken.vendor.permissions.SERVICES : [],
            }
        }
        else {
          throw new Error("Please verify the JWT token. The audien claim is not recognized!");
        }
      }
      else
        return {
          ...state,
          token: undefined,
          isPaymentStatusCheckEnabled: false,
          poPermissions: [],
          courteseyDeliveryPermissions: [],
          accountSettingPermissions: [],
          bankAccountPermissions: [],
          taxIdPermssions: [],
          authorizationEmailPermissions: [],
          documentPermisions: [],
          servicesPermissions: [],
          isProductCodeFeatureEnabled: false,
          isFlatRateFeatureEnabled: false,
          isPreventFractionalQtyFeatureEnabled: false,
          isLockNationalAccountPricingFeatureEnabled: false,
          isHideClientPricingFeatureEnabled: false,
          isPreventLineEditFeatureEnabled: false,
          isPMServicesReadOnlyFeatureEnabled: false,
          isMissingProductCodeWorkFlowEnabled: false,
          isStoreAssociationLineIdFeatureEnabled: false,
          isDeleteAssociatedLinesFeatureEnabled: false,
          isQtySyncFeatureEnabled: false,
          isAllowMutuallyExclusiveATAsFeatureEnabled: false,
          isAllowMultipleLinesWithSameATAFeatureEnabled: false,
          isReviewOpenPOsUponLoginEnabled:false,
          isPriceControlFeatureEnabled: false,
          isIEWarningFeatureEnabled: false,
          isAccidentAssignmentFeatureEnabled: false,
          overrideTaxThresholdLimit: false
        }

    }
      break;
    case "VENDOR_TYPE_CHANGED": {
      return { ...state, serviceClassification: action.serviceClassification }

    }

    case "VENDOR_FEATURES_LOADED": {
      return {
        ...state,
        isPaymentStatusCheckEnabled: action.vendorFeatures.includes('is_payment_status_check_enabled'),
        isProductCodeFeatureEnabled: action.vendorFeatures.includes('enable_product_codes'),
        isFlatRateFeatureEnabled: action.vendorFeatures.includes('enable_flat_rate'),
        isPreventFractionalQtyFeatureEnabled: action.vendorFeatures.includes('prevent_qty_fractional'),
        isLockNationalAccountPricingFeatureEnabled: action.vendorFeatures.includes('lock_na_pricing'),
        isHideClientPricingFeatureEnabled: action.vendorFeatures.includes('hide_client_contract_pricing'),
        isPreventLineEditFeatureEnabled: action.vendorFeatures.includes('prevent_edit_lines'),
        isPMServicesReadOnlyFeatureEnabled: action.vendorFeatures.includes('pm_read_only_services'),
        isMissingProductCodeWorkFlowEnabled: action.vendorFeatures.includes('missing_product_code'),
        isStoreAssociationLineIdFeatureEnabled: action.vendorFeatures.includes('store_association_line_id'),
        isDeleteAssociatedLinesFeatureEnabled: action.vendorFeatures.includes('del_associate_lines'),
        isQtySyncFeatureEnabled: action.vendorFeatures.includes('qty_sync'),
        isAllowMutuallyExclusiveATAsFeatureEnabled: action.vendorFeatures.includes('allow_mutually_exclusive_atas'),
        isAllowMultipleLinesWithSameATAFeatureEnabled: action.vendorFeatures.includes('allow_multiple_lines_with_same_ata'),
        isReviewOpenPOsUponLoginEnabled:action.vendorFeatures.includes('review_open_pos_upon_login'),
        isPriceControlFeatureEnabled: action.vendorFeatures.includes('validate_line_item_fixed_price'),
        isIEWarningFeatureEnabled: action.vendorFeatures.includes('warn_ie_users'),
        isAccidentAssignmentFeatureEnabled: action.vendorFeatures.includes('accman_accident_assignments'),
        overrideTaxThresholdLimit: action.vendorFeatures.includes('override_tax_threshold_limit')
      }
    }

    case "VENDOR_IS_DEALER": {
      return { ...state, dealerDraftStatus: action.dealerDraftStatus }

    }
    case "AGED_PO_COUNT": {
      return { ...state, agedPOsCount: action.agedPOsCount }

    }
    default:
      return state
  }
}

export { initialState }
export default appStateReducer