import * as constants from 'constants/App';
import initialAppState from './InitialAppState';
import {fromJS} from 'immutable';

export function uiDataShared(state = initialAppState.getIn(['uiData', 'shared']), action) {
    switch (action.type) {

        case constants.FETCH_VEH_DATA_FOR_PO_CREATION_REQUESTED:
            return state.set('isFetchingVehicleDataForPOCreation', true);
        case constants.FETCH_VEH_DATA_FOR_PO_CREATION_RESOLVED:
            return state.set('isFetchingVehicleDataForPOCreation', false);
        case constants.FETCH_VEH_DATA_FOR_PO_CREATION_REJECTED:
            return state.set('isFetchingVehicleDataForPOCreation', false);
        case "PERSONA_USER_STORE_ACCESS_REQUESTED":
            return state.set('showPersonaAccessStoreSpinner',true) 
        case "PERSONA_USER_STORE_ACCESS_RESOLVED":
            return state.set('personaAccessStoreErrorMessage','').set('showPersonaAccessStoreErrorMessage',false).set('showPersonaAccessStoreSpinner',false);
        case "PERSONA_USER_STORE_ACCESS_REJECTED":        
            return state.set('personaAccessStoreErrorMessage',action.errorMessage).set('showPersonaAccessStoreErrorMessage',true).set('showPersonaAccessStoreSpinner',false);    
        case constants.SELECT_LOCALE:
            return state.set('selectedLocale', action.locale);
        case constants.SELECT_CURRENCY:
            return state.set('selectedCurrency', action.currency);
        case "CLEAR_SELECT_LOCALE":
            return state.set('selectedLocale', "en-US");
        case constants.FETCH_DATA_FROM_API_START:
            // Add the component to the Map as it is fetching data
            return state.setIn(['componentsFetchingDataFromAPI', action.componentName], action.componentName);
        case constants.FETCH_DATA_FROM_API_COMPLETE:
            // Remove component as it is done fetching data
            return state.deleteIn(['componentsFetchingDataFromAPI', action.componentName]);
        case constants.SET_ERROR_MESSAGE:
            return state.set('errorMessage', action.errorMessage);
        case constants.CLEAR_ERROR_MESSAGE:
            return state.set('errorMessage', undefined).set('vehicleSearchErrorMessage',undefined).set('showVehicleSearchErrorMessage', false);
        case constants.SET_VEHICLE_SEARCH_ERROR_MESSAGE:
            return state.set('vehicleSearchErrorMessage', action.errorMessage).set('showVehicleSearchErrorMessage',action.errorMessage==undefined || action.errorMessage.length==0?false:true);
        case constants.CLEAR_VEHICLE_SEARCH_ERROR_MESSAGE:
            return state.set('vehicleSearchErrorMessage', undefined);
        case constants.HIDE_VEHICLE_SEARCH_ERROR_MESSAGE:
            return state.set('showVehicleSearchErrorMessage', false).set('vehicleSearchErrorMessage',undefined).set('showVehicleSearchErrorMessage', false).set('existingPoClicked',undefined);
        case constants.SET_SELECTED_COUNTRY:
            return state.set('selectedCountry', action.country);
        case constants.CLEAR_VEHICLE_SEARCH_VIN:
            return state.set('vehicleSearchVin8Value', undefined);
        case constants.SET_VEHICLE_SEARCH_VIN:
            return state.set('vehicleSearchVin8Value', action.vin8);
        case constants.EXISTING_PO_YES:
            return state.set('existingPoClicked',true);
        case constants.SET_UI_ERROR_MESSAGE:
            return state.set('errorMessage', action.errorMessage);
        case constants.CLEAR_UI_ERROR_MESSAGE:
            return state.set('errorMessage', undefined);            
        case  constants.TOGGLE_TERMS_AND_CONDITIONS_ACCEPTED:
            return state.setIn(['vendorTermsAndConditions','toggleAcceptedFlag'], !state.getIn(['vendorTermsAndConditions','toggleAcceptedFlag']));
         case  constants.TC_INITIALS_TERMS_AND_CONDITIONS_ACCEPTED:
            return state.setIn(['vendorTermsAndConditions','tcInitialsAcceptedFlag'], action.tcInitialsValue);  
            case  constants.TC_CONTINUE_TERMS_AND_CONDITIONS_FLAG:
                return state.setIn(['vendorTermsAndConditions','tcContinueValdiationFlag'], true); 
        case  constants.FETCH_TERMS_AND_CONDITIONS_REQUESTED:
            return state.setIn(['vendorTermsAndConditions','isFetchingTermsAndCondition'], true);
        case  constants.FETCH_TERMS_AND_CONDITIONS_RESOLVED:
            return state.setIn(['vendorTermsAndConditions','isFetchingTermsAndCondition'], false);
        case  constants.FETCH_TERMS_AND_CONDITIONS_REJECTED:
            return state.setIn(['vendorTermsAndConditions','isFetchingTermsAndCondition'], false);
        case  constants.FETCH_TERMS_AND_CONDITIONS_ACCEPTED_REQUESTED:
            return state.setIn(['vendorTermsAndConditions','isFetchingTermsAndConditionAccepted'], true);
        case constants.FETCH_TERMS_AND_CONDITIONS_ACCEPTED_RESOLVED:
            return state.setIn(['vendorTermsAndConditions','isFetchingTermsAndConditionAccepted'], false);
        case constants.FETCH_TERMS_AND_CONDITIONS_ACCEPTED_REJECTED:
            return state.setIn(['vendorTermsAndConditions','isFetchingTermsAndConditionAccepted'], false);
        case constants.CLEAR_TERMS_AND_CONDITIONS:
            return state.setIn(['vendorTermsAndConditions','isFetchingTermsAndConditionAccepted'], false).setIn(['vendorTermsAndConditions','isFetchingTermsAndCondition'], false).setIn(['vendorTermsAndConditions','toggleAcceptedFlag'],false).setIn(['vendorTermsAndConditions','tcInitialsAcceptedFlag'],undefined).setIn(['vendorTermsAndConditions','tcContinueValdiationFlag'],false);
        case  constants.FETCH_TERMS_STATUS_REQUESTED:
            return state.setIn(['vendorTermsAndConditions','isFetchingTermsStatus'], true);
        case  constants.FETCH_TERMS_STATUS_RESOLVED:
            return state.setIn(['vendorTermsAndConditions','isFetchingTermsStatus'], false);
        case  constants.FETCH_TERMS_STATUS_REJECTED:
            return state.setIn(['vendorTermsAndConditions','isFetchingTermsStatus'], false);
        case constants.SAVE_IS_WORK_COMPLETION_FEATURE_ENABLED:
            return state.setIn(['features','workCompletion'], action.isWorkCompletionFeatureEnabled);  
        case constants.SAVE_FIELD_VALIDATIONS:
            let fieldValidations = state.get('fieldValidations');
            const fields = fieldValidations.keySeq().toArray();
            for (var i = 0; i < fields.length; i++) {
                fieldValidations = fieldValidations
            .setIn([fields[i],'isRequired'],action.fieldValidations[fields[i]].isRequired)
            .setIn([fields[i],'minAllowedLength'],action.fieldValidations[fields[i]].minAllowedLength)
            .setIn([fields[i],'maxAllowedLength'],action.fieldValidations[fields[i]].maxAllowedLength)
            .setIn([fields[i],'isVisible'],action.fieldValidations[fields[i]].isEnabled)
            }       

            return state.set('fieldValidations',fieldValidations);   
        case "SET_PERSONA_DETAILS":        
            return state.set('selectedLocale',action.selectedLocale);             
        case "SAVE_OVERRIDE_TAX_LIMIT":
            return state.setIn(['features','overrideTaxLimit'], action.overrideTaxLimit); 
        default:
            return state;
    }
}

export function serverDataShared(state = initialAppState.getIn(['serverData', 'shared']), action) {

    switch (action.type) {
        case constants.SAVE_VEH_DATA_FOR_PO_CREATION_DATA:
            return state.set('vehicleDetails', fromJS(action.vehicleDetails));
        case "SAVE_VENDOR_LABOR_RATE_DETAILS":        
            return state.set('laborRateDetails', fromJS(action.laborRateDetails));
        case "SAVE_LABOR_RATE_WINDOW_SHOWN_FOR_SESSION":
            return state.set('laborRateWindowShownForThisSession',true)
        case constants.SAVE_MULTI_VEH_DATA_FOR_PO_CREATION_DATA:
            return state.set('multiVehicleDetails', fromJS(action.vehicleDetails));
        case constants.CLEAR_MULTI_VEH_DATA_FOR_PO_CREATION:
            return state.set('multiVehicleDetails',undefined);
        case constants.SAVE_MULTI_CLIENT_PO_DATA_FOR_PO_CREATION_DATA:
            return state.set('multiClientPODetails', fromJS(action.purchaseOrderData));
        case constants.CLEAR_SAVE_MULTI_CLIENT_PO_DATA_FOR_PO_CREATION_DATA:
            return state.set('multiClientPODetails',undefined);
        case "SAVE_VENDOR_ADDRESS":            
            return state.set('businessAddress',fromJS(action.businessAddress));
        case "SAVE_PERSONA_SIGN_IN_DETAILS":            
            return state.set('personaToken',action.personaToken).set('adminUser', action.adminUser).set('personaUserId',action.personaUserId);
        case "SET_PERSONA_TOKEN":            
            return state.set('personaToken',action.personaToken);
        case "SAVE_PERSONA_SIGN_IN_USER_DETAILS":            
            return state.set('personaUser',action.personaUser);
        case constants.SAVE_SIGN_IN_DETAILS:
            return state.set('token', action.token).set('vendorName', action.vendorName).set('vendorId', action.vendorId).set('vendorAccountType',action.vendorAccountType).set('corporateId',action.corporateId).set('isSSOUser',action.isSSOUser==undefined?false:action.isSSOUser);
        case "SET_TOKEN":
            return state.set('token', action.token);
       case constants.ADD_ATA_CODES_BY_SEARCH_KEY:
            return state.setIn(['ataCodesBySearchKeys', 'searchKey'], action.searchKey);
        case constants.CLEAR_ATA_CODES_BY_SEARCH_KEY:
            // TODO - convert empty array to an Immutable array
            return state.setIn(['ataCodesBySearchKeys', 'searchKey'], []);
        case constants.FETCH_PO_DATA_RESOLVED:
            return state.set('vehicleDetails', fromJS(action.purchaseOrderData.vehicleDetails));        
        case constants.LOAD_RESTRICTED_ATA_CODES_DATA:
            return state.setIn(['restrictedATACodes'], action.restrictedATACodes.get('ataCodes'));
        case constants.CLEAR_EXISTING_PO:
            return state.setIn(['vehicleDetails', 'existingOrderWaitingOrApprovedAuthorization'],0).setIn(['vehicleDetails', 'existingOrderPreviouslyVoided'],0);
        case constants.SET_VENDOR_VEHICLE_PARAMETERS:
            return state.set('vendorVehicleParameters',fromJS(action.vendorVehicleParameters));
        case constants.RESET_VENDOR_VEHICLE_PARAMETERS:
            return state.set('vendorVehicleParameters',undefined);
        case constants.SAVE_CORPORATE_VENDOR_DETAILS:
            return state.set('vendorId', action.subId).set('corporateId',action.masterId);
        case constants.CLEAR_CORPORATE_VENDOR_DETAILS:
            return state.set('corporateId',null);
        case "SAVE_VENDOR_TYPE":
            return state.set('serviceClassification',action.serviceClassification);
        case "SAVE_DEALER_DRAFT_DETAILS":
            return state.set('isDealerDraft',action.dealerDraft);
        case constants.SAVE_TERMS_AND_CONDITIONS_STATUS:
            return state.set('acceptedTermsAndConditions',action.termsAndConditionsFlag);
        case "VENDOR_PAYMENT_STATUS":
            return state.set('paymentType',action.paymentType);
        case constants.SAVE_VENDOR_TERMS_AND_CONDITIONS:
            return state.set('vendorTermsAndConditions',action.vendorTermsAndConditions);
        
        case "SET_PERSONA_DETAILS":        
            return state.set('personaToken',action.personaToken).set('personaUserId',action.personaUserId).set('adminUser',action.adminUser).set('personaUser',action.personaUser);
        default:
            return state;
    }
}



